var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_profile") } },
    [
      _c(
        "a-row",
        {
          attrs: {
            gutter: [16, 16],
            type: "flex",
            align: "middle",
            justify: "center"
          }
        },
        [
          _c(
            "a-col",
            { attrs: { span: 4 } },
            [_c("a-avatar", { attrs: { size: 150, icon: "user" } })],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 18 } },
            [
              _c(
                "a-descriptions",
                { attrs: { layout: "vertical", column: 3 } },
                [
                  _c(
                    "a-descriptions-item",
                    { attrs: { label: _vm.$t("lbl_company_name") } },
                    [_vm._v(" " + _vm._s(_vm.store.authData.companyName) + " ")]
                  ),
                  _c(
                    "a-descriptions-item",
                    { attrs: { label: _vm.$t("lbl_email") } },
                    [_vm._v(" " + _vm._s(_vm.store.authData.email) + " ")]
                  ),
                  _c(
                    "a-descriptions-item",
                    { attrs: { label: _vm.$t("lbl_employee_name") } },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.store.authData.employeeName) + " "
                      )
                    ]
                  ),
                  _c(
                    "a-descriptions-item",
                    { attrs: { label: _vm.$t("lbl_username") } },
                    [_vm._v(" " + _vm._s(_vm.store.authData.username) + " ")]
                  ),
                  _c(
                    "a-descriptions-item",
                    { attrs: { label: _vm.$t("lbl_role") } },
                    [
                      _c("a-tag", { attrs: { color: "blue" } }, [
                        _vm._v(" " + _vm._s(_vm.userRoles) + " ")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { type: "flex" } },
        [
          _c(
            "a-col",
            { attrs: { span: 24, align: "end" } },
            [
              _c(
                "router-link",
                { attrs: { to: { name: "profile.change-password" } } },
                [
                  _c("a-button", { attrs: { type: "link", icon: "select" } }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("lbl_profile_change_password")) + " "
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }